.cardContainer {
    width: 100%;
    height: fit-content;

    .row {

        @media(max-width: 1199px) {
            justify-content: center;

        }

        .card {
            border-radius: 10px;
            padding: 30px;
            height: fit-content;
            width: 100%;

            @media (max-height : 830px) or (max-width:1400px) {
                padding: 20px;
            }

            .imageBack {
                background-color: white;
                display: inline-block;
                padding: 20px;
                border-radius: 10px;
                justify-content: center;
                align-items: center;

                img {
                    @media (max-height : 830px) or (max-width:1400px) {
                        width: 30px;
                        height: 30px;
                    }

                }

            }

            h1 {
                margin: 10px 0 0;
                font-size: 3rem;
                font-weight: 700;
                color: white;

                @media (max-height : 830px) or (max-width:1400px) {
                    font-size: 2rem;
                }



            }

            h4 {
                margin: 0;
                color: white;
                font-size: 1.1rem;
                font-weight: 400;

                @media (max-height : 830px) or (max-width:1400px) {
                    font-size: 0.9rem;
                }

            }

        }
    }
}