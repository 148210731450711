.changePassword {
    height: inherit;
    display: flex;
    flex-direction: column;
    width: 100%;
    overflow-x: hidden;
    overflow-y: overlay;

    .contentTitle {
        margin-bottom: 40px;
        color: white;
    }

    .content {
        width: 500px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin: auto;
        height: inherit;

        @media (max-width : 600px) {
            width: 100%;

        }



        .row {
            width: 100%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            margin: 20px 0;

        }

        .submit {
            width: 350px;
            margin: 80px auto 20px;
            border-radius: 50px;
            background: linear-gradient(315deg, #1162CD 0%, #1DBDE1 100%);
            border: none;
            color: white;
            font-size: 1.6rem;
            min-height: 50px;
            font-weight: 600;
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;

            @media (max-height:700px) {
                margin: 80px auto 30px;
            }

            @media (max-width : 600px) {
                width: 100%;

            }
        }
    }


}