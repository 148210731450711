.Transaction {
    height: inherit;
    padding: 10px 20px;
    width: 100%;
    display: flex;
    flex-direction: column;
    overflow-x: hidden;
    overflow-y: overlay;

    .headerContainer {
        display: flex;
        flex-direction: row;
        margin: 0px 0 10px;
        color: gray;


        .backButton {
            cursor: pointer;
            opacity: 1;
            font-size: 1.6rem;
            display: flex;
            flex-direction: row;
            align-items: center;
            margin-right: auto;

            img {
                width: 25px;
                height: 15px;
            }

            h1 {
                margin: 0px 7px;
                font-size: 1.5rem;
                color: gray;
            }
        }
    }
}